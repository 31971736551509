

.contact section
{
    padding:100px;
    
}

.h3c{
    padding-left: 20px;
}






.content {
    display:flex;
    justify-content: space-between ;   
    padding: 100px; 
   
   
}
.contentBx{
    padding-right:30px ;
}
.contentBx h3 {
    font-size: 24px;
    margin-bottom: 10px;

}
.w50{
     min-width:50%;
}






.contact{
    background: #203262;

}

.formBx{
     min-width: 60%;
     padding-right: 70px;
     
}
.formBx form{
    display: flex;
    flex-direction: column;
}
 .formBx  form h3,
 .contactInfo h3 {
     color: #fff;
     font-size: 20px;
     font-weight: 500;
     margin-bottom: 10px;
 }

.formBx form input,
.formBx form  textarea
{
    margin-bottom: 20px;
    padding: 15px;
    font-size: 16px;
    /* background: transparent; */
    border:none;
    outline:none;
    background: #fff;
     color: #000;
     resize: none;
}

.formBx form  textarea
{
    min-height: 10px;

}

.formBx form input ::placeholder,
.formBx form  textarea ::placeholder
{
    color:#999 ;
}

.formBx form input[type="submit"]
{
    max-width: 150px;
    background-color: #2196f3;
    cursor: pointer ;
    text-transform: uppercase;
    letter-spacing: 2.2px;
    border-radius: 0px;
    color: #fff;
}
.formBx form input[type="submit"]:hover{
    transition: 0.2s ease-in-out;
    background-color: #f0e2e2;
    color: black;
}

.contactInfo {
    min-width: 40%;
}
.contactInfobx{
    position: relative;

}
.contactInfobx .box{
    position: relative;
    padding: 20px 0;
    display :flex
}
.contactInfobx .box .icon{
    min-width: 40px;
    padding-top: 4px;
    color: #fff; 
    display: flex;
    justify-content: center ;
    align-items: flex-start ;
    font-size:24px ;
}

.contactInfobx .box .text{
    display: flex;
    margin-left:20px ;
    font-size: 16px;
    color:#fff;
    flex-direction: column;
    font-weight: 300;
  
}
.contactInfobx .box .text h3 {
    font-weight: 500;
    color: #2196f3;
    margin-bottom: 0;

}

@media (max-width:991px){

.formBx {
    padding-right: 0;
}
.h3c{
    padding-left: 0;
}
.contactInfo .box{
    padding-left: 0px;
}
.contentBx h3 {
    font-size: 20px;

}
.content{
    flex-direction: column;
}
.w50{
    margin-bottom: 20px;

}
.services .content .skillsBx {
    margin: 10px;
}
}

@media (max-width:600px){
 header ,
 header .sticky{
     padding:20px 20px;
 }
 .banner {
     padding: 150px 20px 100px;
 }
 section{
     padding:100px 20px;
 }
}